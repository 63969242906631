import React, { useEffect } from "react";
import AOS from "aos";
import "./Services.css";
// import Ellipse from "../../assets/Ellipse 1197.png";
import Mobile from "../../assets/MobAppIcon.png";
import Laptop from "../../assets/Product Consulting Engeneering.png";
import UIUX from "../../assets/UIUXDesigning.png";
import Globe from "../../assets/Product TestingModernization.png";
import Key from "../../assets/Resources Agumentation.png";
import Cloud from "../../assets/CloudIcon.png";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
    <section id="services">
      <div className="container-fluid services">
        <div className=" container">
          <div
            className="service-heading"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="1000"
          >
            <h4>We Build world class digital products</h4>
            <div className="horizontalLine2"> </div>
            <h1>
              Exclusive <span>Web & Mobile App</span> <br />
              development company
            </h1>
            <h6 className="service-text">
              Our complete spectrum of mobile app development and web solutions
              help startups, small-medium enterprises, and organizations <br />
              to create outcome-focused solutions with enhanced agility that
              help them to rapidly reshape, renew and build new capabilities for
              their business.
            </h6>
          </div>

          <div className="service-sideimg">
            {/* <div className="service-menu">
            <img src={Ellipse} alt="" />
          </div> */}
            <div className="service-body">
              <div className="service-main">
                <div
                  className="box"
                  data-aos="zoom-in-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="ser-img">
                    <img src={Mobile} alt="" />
                  </div>
                  <h5>
                    Web & Mobile App <br />
                    Development
                  </h5>
                </div>
                <div
                  className="box"
                  data-aos="zoom-in-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="ser-img">
                    <img src={Laptop} alt="" />
                  </div>
                  <h5>
                    Product Consulting <br />& Engeneering
                  </h5>
                </div>
                <div
                  className="box"
                  data-aos="zoom-in-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="ser-img">
                    <img src={UIUX} alt="" />
                  </div>
                  <h5>
                    UI UX <br />
                    Designing
                  </h5>
                </div>
                <div
                  className="box"
                  data-aos="zoom-in-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="ser-img">
                    <img src={Globe} alt="" />
                  </div>
                  <h5>
                    Product Testing <br />& Modernization
                  </h5>
                </div>
                <div
                  className="box"
                  data-aos="zoom-in-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="ser-img">
                    <img src={Key} alt="" />
                  </div>
                  <h5>
                    Resources <br />
                    Agumentation
                  </h5>
                </div>
                <div
                  className="box"
                  data-aos="zoom-in-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="ser-img">
                    <img src={Cloud} alt="" />
                  </div>
                  <h5>Devops Services</h5>
                </div>
              </div>
            </div>
          </div>
          {/* <button type="button" className="button">
        VIEW SERVICES <img src={gicon} alt="" />
      </button> */}
        </div>
      </div>
    </section>
  );
};
export default Services;
