import React, { useEffect } from "react";
import AOS from "aos";
import "./Products.css";
import dots from "../../assets/Dots.png";
import Commercial from "../../assets/CommercialImg.png";

const Products = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section id="Products">
      <div className="container-fluid Products">
        <div className=" container">
          <div className="product-leftside">
            <div className="product-menu">
              <img src={Commercial} alt="" />
            </div>

            <div className="product-right">
              <h4>Driving Digital Transformation</h4>
              <h1>Industries we serve</h1>
              <h5>
                Empowering businesses across verticals by leveraging the power
                of mobility that help <br />
                accelerate innovation, reduce costs and improve performance.
              </h5>
              <div className="product-industriesList">
                <ol className="two-column-list">
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Ecommerce & Retail
                  </li>
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Fintech
                  </li>
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Healthcare & fitness
                  </li>
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Food & Restaurant
                  </li>
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Transport & automation
                  </li>
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Beauty & wellness
                  </li>
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Real Estate
                  </li>
                  <li
                    className="action-item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-anchor-placement="center-bottom"
                  >
                    On demand solutions
                  </li>
                  {/* <li
                className="action-item"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-anchor-placement="center-bottom"
              >
                Many more
              </li> */}
                </ol>
                <div className="product-opt">
                  <img src={dots} alt="" />
                  <p>Many More</p>
                </div>
              </div>
              {/* <div className="product-expertise">
            <div className="expertiseTitle" data-aos="fade-up">
              Our Expertise{" "}
            </div>
            <br />
            <div className="expertiseList">
              <ul>
                <li
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-duration="900"
                >
                  Politician management app
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-duration="900"
                >
                  Task management system apps
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-duration="900"
                >
                  CRM software solutions
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-duration="900"
                >
                  Clinical management system
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-duration="900"
                >
                  Fitness apps
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-duration="900"
                >
                  EHR and EMR software solutions
                </li>
              </ul>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
