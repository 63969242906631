import React, { useEffect } from "react";
import "./Work.css";
import AOS from "aos";
// import arrowgrey from "../../assets/ArrowGrey.png";
// import arrowblue from "../../assets/ArrowBlue.png";
import img1 from "../../assets/img-1.png";
import img2 from "../../assets/img-2.jpg";
import img3 from "../../assets/img-3.png";
// import gicon from "../../assets/gicon.png";
import arrow from "../../assets/arrow.png";
const Work = () => {
  const handleClick = (url) => {
    window.open(url, "_blank"); // Open the URL in a new tab
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="work">
      <div className="container-fluid work ">
        <div className=" container">
          <div className="work-header" data-aos="fade-up">
            <h4>When Creative idea meets</h4>
            <div className="work-right-text">
              <h1>
                Explore our <span>Recent Work</span>
              </h1>
            </div>
          </div>
          <div className="work-body">
            <div className="work-images" data-aos="zoom-in-up">
              <img
                src={img1}
                alt=""
                onClick={() => handleClick("https://www.hapido.com/ae-en/")}
              />
              <div className="bottom-left">
                <a href="https://www.hapido.com/ae-en/" target="blank">
                  <h5>
                    <span>Hapido.com</span> <br />
                    E-commerce
                  </h5>
                  <img src={arrow} alt="" />
                </a>
              </div>
            </div>
            <div className="work-images" data-aos="zoom-in-up">
              <img
                src={img2}
                alt=""
                onClick={() => handleClick("https://www.itechgenicglobal.com/")}
              />
              <div className="bottom-left">
                <a href="https://www.itechgenicglobal.com/" target="blank">
                  <h5>
                    <span>itechgenic-Devboost</span> <br />
                    Project Risk Advisor
                  </h5>
                  <img src={arrow} alt="" />
                </a>
              </div>
            </div>
            <div className="work-images" data-aos="zoom-in-up">
              <img
                src={img3}
                alt=""
                onClick={() => handleClick("https://www.trustin.ae/")}
              />
              <div className="bottom-left">
                <a href="https://www.trustin.ae/" target="blank">
                  <h5>
                    <span>Trustin Trade</span> <br />
                    Digital Escrow Platform
                  </h5>
                  <img src={arrow} alt="" />
                </a>
              </div>
            </div>
          </div>
          {/* <button type="button" className="button">
          VIEW ALL PROJECTS <img src={gicon} alt="" />
        </button> */}
        </div>
      </div>
    </section>
  );
};

export default Work;
