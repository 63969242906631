import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Marquee from "react-fast-marquee";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Hero.css";
import itgenic from "../../assets/itechLogo.png";
import encred from "../../assets/encred-logo.png";
import exactspace from "../../assets/exactspace-logo.png";
import ententia from "../../assets/extentia-logo.webp";
import hapido from "../../assets/Hapido logo.svg";
import healthi from "../../assets/healthi-logo.png";
import itspark from "../../assets/itspark-logo.png";
import namah from "../../assets/namah-logo.png";
import supersourcing from "../../assets/supersourcing.svg";
import takespeks from "../../assets/techspakes-logo.png";
import trustin from "../../assets/trustin logo.svg";
import uex from "../../assets/uex logo.svg";
import gicon from "../../assets/gicon.png";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="hero">
      <div className="container-fluid main-hero">
        <div className=" hero container">
          <div className="hero-text">
            <h1 data-aos="fade-up" data-aos-duration="400">
              The Ultimate Mobile App <br />& Web Solutions For Many Trade
            </h1>
            <p data-aos="fade-up" data-aos-duration="400">
              Accelerate your business growth with our customer-led, insights
              driven and innovative solutions
            </p>
            <Link to="contacts" smooth={true} offset={-60} duration={500}>
              <button className="button">
                START A PROJECT <img src={gicon} alt="" className="icon" />
              </button>
            </Link>
          </div>
          <div className="logos">
            <Marquee speed={40} pauseOnHover={true}>
              <div className="logos-slide">
                <img src={itgenic} alt="" />
                <img src={encred} alt="" />
                <img src={exactspace} alt="" />
                <img src={ententia} alt="" />
                <img src={hapido} alt="" />
                <img src={healthi} alt="" />
                <img src={itspark} alt="" />
                <img src={namah} alt="" />
                <img src={supersourcing} alt="" />
                <img src={takespeks} alt="" />
                <img src={trustin} alt="" />
                <img src={uex} alt="" />
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
