import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";
import Products from "./components/Products/Products";
import Work from "./components/Work/Work";
import Projects from "./components/Projects/Projects";
import Client from "./components/Clients/Client";
import Contact from "./components/Contacts/Contact";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import ScrollToTopButton from "./components/ScrollToTop/ScrollToTop";

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <Hero />
          <Services />
          <Products />
          <Work />
          <Projects />
          <Client />
          <Contact />
          <Footer />
          <ScrollToTopButton />
        </>
      )}
    </div>
  );
};

export default App;
