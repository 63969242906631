import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "./Projects.css";
import gicon from "../../assets/gicon.png";
import ClientInteraction from "../../assets/ClientInteraction.png";
import Consolidation from "../../assets/Consolidation.png";
import Deliverables from "../../assets/Deliverables.png";
import Development from "../../assets/Development.png";

const Projects = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
    <div className="container-fluid projects">
      <div className=" container">
        <div className="project">
          <div className="project-left" data-aos="fade-up">
            <div className="project-top">Our Customer on-boarding Process</div>
            {/* <div className="horizontalLine5"> </div> */}
            <div className="project-title">
              <h3>
                Let’s Step Towards <br />
                <span className="spanBold">Purposeful Relationships</span>
              </h3>
            </div>
            <div className="project-tagline">
              Infeon is your empathetic partner in defining your achievements &
              success. Our streamlined process of customer on-boarding is
              frictionless, diligent, & breeds loyalty. We follow a four-pronged
              process to collaborate with our clients across geographical
              locations. Here’s how we map clients’ on-boarding journey and key
              milestones.
            </div>
            <Link to="contacts" smooth={true} offset={-60} duration={500}>
              <button className="button" type="button">
                LETS WORK TOGETHER <img src={gicon} alt="" />
              </button>
            </Link>
          </div>
          <div className="project-right">
            <card className="cardCSS lt" data-aos="zoom-out">
              <div className="iconCss">
                <img src={ClientInteraction} alt="Client Interaction" />
              </div>
              <div className="cardTitle">Client Interaction</div>
              <ul className="project-card-list">
                <li>Call or Meeting</li>
                <li>Requirement gathering & Analysis</li>
                <li>Problem Definition</li>
              </ul>
            </card>
            <card className="cardCSS rt" data-aos="zoom-out">
              <div className="iconCss">
                <img src={Development} alt="Development" />
              </div>
              <div className="cardTitle">Development</div>
              <ul className="project-card-list">
                <li>Sprint Planning</li>
                <li>Agile Development</li>
                <li>UAT</li>
              </ul>
            </card>
            <card className="cardCSS rb" data-aos="zoom-out">
              <div className="iconCss">
                <img src={Consolidation} alt="Consolidation" />
              </div>
              <div className="cardTitle">Consolidation</div>
              <ul className="project-card-list">
                <li>Business Requirement Document</li>
                <li>Wireframes</li>
                <li>Fidelity Prototypes</li>
              </ul>
            </card>
            <card className="cardCSS lb" data-aos="zoom-out">
              <div className="iconCss">
                <img src={Deliverables} alt="Deliverables" />
              </div>
              <div className="cardTitle">Deliverables</div>
              <ul className="project-card-list">
                <li>A Working System</li>
                <li>Release documentation</li>
                <li>Training Sessions</li>
              </ul>
            </card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
