// ScrollToTopButton.js
import React, { useState, useEffect } from "react";
import "./ScrollToTop.css"; // Import the CSS for styling

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const scrollPercent =
        (scrollPosition / (scrollHeight - windowHeight)) * 100;

      if (scrollPosition > 150) {
        setVisible(true);
        setScrollProgress(scrollPercent);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      className={`scroll-to-top ${visible ? "visible" : "hidden"}`}
      onClick={scrollToTop}
      style={{ "--scroll-progress": `${scrollProgress}%` }} // Pass scroll progress as a CSS variable
    >
      <span className="icon">↑</span>
    </button>
  );
};

export default ScrollToTop;
